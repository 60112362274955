let config = module.exports = {};

config.env = 'development'
config.hostname = 'darall.pro'

// config.operator_tid = 1374806962 // Darall
// config.operator_tid = 469966702 // Alexey Voloshin

// mongo database
config.mongo = {}
config.mongo.uri = process.env.MONGO_URI || 'localhost'
config.mongo.db = 'example_dev'

// map
config.map = {}
// config.map.url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
config.map.url = 'https://map.mynex.ru/tile/{z}/{x}/{y}.png'

// timezone
config.tz = '+10:00',
config.tz_string = 'Asia/Vladivostok',
config.city = 'Владивосток',

//paginate
config.paginateItemsCount = 25

// api
config.api = {}
// config.api.url = 'https://api.darall.mynex.ru/'
config.api.delivery = 'https://api.darall.pro/v1/'
config.api.mynex = 'https://api.mynex.ru/v1/'

// geofence
config.geofence = {}
config.geofence.savetime = 40 // время перед сохранением 4 секунды
config.geofence.name = '' // название геозоны
config.geofence.price = [195, 0, 0, 0, 0] // цена поездки
config.geofence.notify = false // уведомлять или нет при посещении
config.geofence.color = {}
config.geofence.color.count = 15 // количество цветов
config.geofence.color.saturation = 80 // контрастность
config.geofence.color.lightness = 50 // яркость
config.geofence.color.random = true // случайный цвет при создании
config.geofence.color.index = 0 // индекс цвета при random=false

// distance default price
config.distance_price = {}
config.distance_price.courier = 250
config.distance_price.point = 290

// user
config.user = {}
// user status
config.user.status = {}
config.user.status.free = 0
config.user.status.processing = 1
config.user.status.delivering = 2
config.user.status.deleted = 3
// user roles
config.user.role = {}
config.user.role.none = 0
config.user.role.operator = 1
config.user.role.courier = 2
config.user.role.point = 3

// order
config.order = {}
// order status
config.order.status = {}
config.order.status.new = 0
config.order.status.way_point = 1
config.order.status.way_guest = 2
config.order.status.complete = 3
config.order.status.forming = 4
config.order.status.passed = 5
config.order.status.deleted = 6
// order source
config.order.source = {}
config.order.source.telegram = 0
config.order.source.iiko = 1
config.order.source.iikoBiz = 2
config.order.source.mail = 3

// link
config.link = {}
config.link.type = {}
config.link.type.review = 0
config.link.type.position = 1

// review
config.review = {}
// review status
config.review.status = {}
config.review.status.new = 0
config.review.status.opened = 1
config.review.status.voted = 2
config.review.status.closed = 3
config.review.status.responded = 4
config.review.status.deleted = 5


// position
config.position = {}
// position status
config.position.status = {}
config.position.status.new = 0
config.position.status.opened = 1
config.position.status.closed = 2
config.position.status.deaded = 3
config.position.status.deleted = 4


// notify
config.notify = {}
// notify status
config.notify.status = {}
config.notify.status.new = 0
config.notify.status.success = 1
config.notify.status.error = 2
config.notify.status.access_denied = 3
config.notify.status.deleted = 4


// interface
config.interface = {}
// config.interface.operatorName = 'supra'
config.interface.time_ready_by_delivery = true
config.interface.courier_passed = true
config.interface.courier_minimal = true
config.interface.address_from_show = false
config.interface.pay_show = true
config.interface.passed_show = false
config.interface.courier_create_show = false
config.interface.courier_help_show = false
config.interface.create_text = 'Готов'
config.interface.timeNotification = 120000
config.interface.courierTrackerNotification = false
config.interface.hourNotifyBegin = 10
config.interface.hourNotifyEnd = 22
config.interface.hourNewDay = 3
config.interface.courierHideMessages = true
config.interface.courierOrderCancelEnable = true
config.interface.courierOrderPassedSkip = true
config.interface.courierOrderComment = false
config.interface.courierHideTakeTime = true
config.interface.courierNewOrderNotify = true
config.interface.courierAutoExit = true
config.interface.pointCouriersAccess = true
config.interface.courierAutoSelectPoint = true