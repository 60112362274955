import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import axios from 'axios'
import Vuex from 'vuex'
import config from './config'

import VueYandexMetrika from 'vue-yandex-metrika'

import VueDragAndDropList from 'vue-drag-and-drop-list'
Vue.use(VueDragAndDropList)

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
import { languages } from './i18n'
import { defaultLocale } from './i18n'
const messages = Object.assign(languages)

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

Vue.config.productionTip = false

Vue.prototype.$store = store

// store.commit('logout')

localStorage.removeItem('user')
localStorage.removeItem('token')
localStorage.removeItem('settings')

Vue.prototype.$http = axios
const token = store.state.token
if (token) {
    Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

Vue.prototype.$http.defaults.baseURL = config.api.delivery

if (store.state.user) {
    if (!store.state.settings) {
        store.state.settings = store.state.user.settings
    }

    if (!store.state.settings.tz) {
        store.state.settings.tz = config.tz
    }
    console.log('tz from main', store.state.settings.tz)
    Vue.prototype.$http.defaults.headers.common['tz'] = store.state.settings.tz

    if (!store.state.settings.tz_string) {
        store.state.settings.tz_string = config.tz_string
    }
    console.log('tz_string from main', store.state.settings.tz_string)
    Vue.prototype.$http.defaults.headers.common['tz_string'] = store.state.settings.tz_string


    if (!store.state.settings.city) {
        store.state.settings.city = config.city
    }
    console.log('city from main', store.state.settings.city)
    // Vue.prototype.$http.defaults.headers.common['city'] = store.state.settings.city

    console.log('token from main', token)

    if (!store.state.operator_tid) {
        if (store.state.user.role == config.user.role.operator) {
            store.state.operator_tid = store.state.user.tid
        } else {
            store.state.operator_tid = store.state.user.operator_tid
        }
    }
}

import notify from 'vuetify-notify'
Vue.use(notify, {
    vuetify,
    options: {
        toast: {
            x: 'right',
            y: 'top',
            color: 'success',
            timeout: 4000,
        },
        // dialog: {
        //     width: 400,
        // },
    },
})

var i18n = new VueI18n({
    locale: defaultLocale,
    fallbackLocale: 'ru',
    messages,
})

Vue.use(VueYandexMetrika, {
    id: 70786153,
    router,
    env: config.env
    // env: 'production'
    // env: process.env.NODE_ENV
    // other options
})

console.log('config.env', config.env)

Vue.use(Vuex)

Vue.prototype.$vm = new Vue({
    vuetify,
    router,
    i18n,
    render: h => h(App),
}).$mount('#app')
