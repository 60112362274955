import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from './config'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        status: '',
        loading: false,
        isLogistic: false,
        isSettings: false,
        settingsLock: true,
        isOrderWindowClose: false,
        width: 450,
        freeCouriers: [],
        orderSend: [],
        secu6: '',
        token: '',
        user: {},
        settings: {},
        geofences: '',
        needSave: '',
        operator_tid: 0,
        userIsPoint: 0,
        newOrdersCount: null,
        freeCouriersCount: null, 
        showMarkers: { // выполненные заказы, заказы, точки, курьеры, стрелки
            ordersComplete: false,
            orders: true,
            points: true,
            couriers: true,
            arrows: true,
        },
    },
    plugins: [createPersistedState()],
    mutations: {
        window_width: (state, data) => (state.width = data),
        needSave: (state, data) => (state.needSave = data),
        geofences: (state, data) => (state.geofences = data),
        showMarkers: (state, data) => (state.showMarkers = data),
        auth_request(state) {
            console.log('state auth_request')
            state.status = 'loading'
        },
        secu_init(state, data) {
            // секу нужен для того чтобы все аккаунты без поля секу автоматически вышли и потребовали вход через новый пароль
            console.log('state secu_init')
            state.secu6 = 'secu6'
        },
        auth_success(state, data) {
            console.log('state auth_success', data.token, data.user)
            state.status = 'success'

            state.token = data.token
            state.user = data.user
            state.settings = data.user.settings
            console.log('data.user', data.user)
            console.log('data.user.settings', data.user.settings)
            console.log('state.settings', state.settings)

            if (!state.settings.tz) {
                state.settings.tz = config.tz
            }
            console.log('tz from store', state.settings.tz)
            axios.defaults.headers.common['tz'] = state.settings.tz

            if (!state.settings.tz_string) {
                state.settings.tz_string = config.tz_string
            }
            console.log('tz_string from store', state.settings.tz_string)
            axios.defaults.headers.common['tz_string'] = state.settings.tz_string

            if (!state.settings.city) {
                state.settings.city = config.city
            }
            console.log('city from store', state.settings.city)
            // axios.defaults.headers.common['city'] = state.settings.city

            state.userIsPoint = state.user.role != config.user.role.operator
            if (state.userIsPoint) {
                state.operator_tid = state.user.operator_tid
            } else {
                state.operator_tid = state.user.tid
            }
        },
        auth_error(state) {
            console.log('state auth_error')
            state.status = 'error'
        },
        logout(state) {
            console.log('state logout')
            state.status = ''
            state.token = ''
            state.user = ''
            state.settings = ''
            // state.geofences = ''
            // state.needSave = ''
            state.operator_tid = ''
            state.userIsPoint = ''
        },
        settings(state, data) {
            console.log('state settings', data)
            state.settings = data
        },
    },
    actions: {
        address_to_coord({ commit }, address) {
            return new Promise((resolve, reject) => {
                const params = {
                    city: this.state.settings.city,
                    address,
                }
                axios
                    .get('address_to_coord', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('address_to_coord err', err.stack)
                        reject(err)
                    })
            })
        },
        geofences_get({ commit }) {
            return new Promise((resolve, reject) => {
                const params = {
                    tid: this.state.operator_tid,
                }
                axios
                    .get('geofences', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('geofences_get err', err.stack)
                        reject(err)
                    })
            })
        },
        geofences_set({ commit }, geofences) {
            return new Promise((resolve, reject) => {
                const params = {
                    data: JSON.stringify(geofences),
                    tid: this.state.user.tid,
                }
                axios
                    .post('geofences', params)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('geofences_set err', err.stack)
                        reject(err)
                    })
            })
        },
        settings_get({ commit }) {
            return new Promise((resolve, reject) => {
                const params = {
                    login: this.state.user.name,
                }
                axios
                    .get('user_settings', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('settings_get err', err.stack)
                        reject(err)
                    })
            })
        },
        settings_set({ commit }, settings) {
            return new Promise((resolve, reject) => {
                const params = {
                    login: this.state.user.name,
                    settings,
                }
                axios
                    .post('user_settings', params)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('settings_set err', err.stack)
                        reject(err)
                    })
            })
        },
        guest_set({ commit }, guest) {
            return new Promise((resolve, reject) => {
                axios
                    .post('guest', guest)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('guest_set err', err.stack)
                        reject(err)
                    })
            })
        },
        point_set({ commit }, point) {
            return new Promise((resolve, reject) => {
                axios
                    .post('point', point)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('point_set err', err.stack)
                        reject(err)
                    })
            })
        },
        user_operator_get({ commit }) {
            const params = {
                tid: this.state.operator_tid,
            }
            return new Promise((resolve, reject) => {
                axios
                    .get('user_operator_tid', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('user_operator_get err', err.stack)
                        reject(err)
                    })
            })
        },
        guests_rate_get({ commit }, params) {
            // params.operator_tid = this.state.operator_tid

            return new Promise((resolve, reject) => {
                axios
                    .get('guests_rate', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('guests_rate_get err', err.stack)
                        reject(err)
                    })
            })
        },
        // deserts_get({ commit }, params) {
        //     return new Promise((resolve, reject) => {
        //         axios
        //             .get('deserts', { params })
        //             .then(resp => {
        //                 resolve(resp)
        //             })
        //             .catch(err => {
        //                 reject(err)
        //             })
        //     })
        // },
        user_get({ commit }, tid) {
            const params = {
                tid,
            }
            return new Promise((resolve, reject) => {
                axios
                    .get('user', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('user_get err', err.stack)
                        reject(err)
                    })
            })
        },
        user_set({ commit }, user) {
            return new Promise((resolve, reject) => {
                axios
                    .post('user', user)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('user_set err', err.stack)
                        reject(err)
                    })
            })
        },
        user_delete({ commit }, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete('user', { data: { id } })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('user_delete err', err.stack)
                        reject(err)
                    })
            })
        },
        courier_delete_notify({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('courier_delete_notify', { data })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('courier_delete_notify err', err.stack)
                        reject(err)
                    })
            })
        },
        courier_status_notify({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('courier_status_notify', { data })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        users_get({ commit }) {
            return new Promise((resolve, reject) => {
                const params = {
                    operator_tid: this.state.operator_tid,
                }
                axios
                    .get('operator_users', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('users_get err', err.stack)
                        reject(err)
                    })
            })
        },
        users_set({ commit }, users) {
            return new Promise((resolve, reject) => {
                axios
                    .post('users', { users })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('users_set err', err.stack)
                        reject(err)
                    })
            })
        },
        review_set({ commit }, review) {
            return new Promise((resolve, reject) => {
                axios
                    .post('review', review)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('review_set err', err.stack)
                        reject(err)
                    })
            })
        },
        guest_create({ commit }, order) {
            return new Promise((resolve, reject) => {
                axios
                    .post('guest_create', order)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('guest_create err', err.stack)
                        reject(err)
                    })
            })
        },
        order_set({ commit }, order) {
            return new Promise((resolve, reject) => {
                axios
                    .post('order', order)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('order_set err', err.stack)
                        reject(err)
                    })
            })
        },
        order_delete({ commit }, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete('order', { data: { id } })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('order_delete err', err.stack)
                        reject(err)
                    })
            })
        },
        order_delete_notify({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('order_delete_notify', {
                        name: data.order.name,
                        point_id: data.order.point_id,
                        point_tid: data.order.point_tid,
                        status_order: data.order.status,
                        courier_tid: data.order.courier_tid,
                        user: data.user,
                        role: data.userRole,
                    })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('order_delete_notify err', err.stack)
                        reject(err)
                    })
            })
        },
        order_cancel_notify({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('order_cancel_notify', {
                        name: data.order.name,
                        point_id: data.order.point_id,
                        point_tid: data.order.point_tid,
                        status_order: data.order.status,
                        courier_tid: data.order.courier_tid,
                        user: data.user,
                        role: data.userRole,
                    })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        order_passed_notify({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('order_passed_notify', {
                        name: data.order.name,
                        point_id: data.order.point_id,
                        point_tid: data.order.point_tid,
                        status_order: data.order.status,
                        courier_tid: data.order.courier_tid,
                        user: data.user,
                        role: data.userRole,
                    })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        restore_order({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .patch('restore_order', {
                        orderId: data.orderId,
                        status: data.status,
                        notify: data.notify,
                        user: data.user,
                        role: data.role,
                    })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('restore_order err', err.stack)
                        reject(err)
                    })
            })
        },
        courier_orders_status({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('courier_orders_status', data)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('courier_orders_status err', err.stack)
                        reject(err)
                    })
            })
        },
        reviews_date({ commit }, data) {
            return new Promise((resolve, reject) => {
                const params = {
                    operator_tid: this.state.operator_tid,
                    date1: data.date1,
                    date2: data.date2,
                    point_id: data.point_id,
                    courier_tid: data.courier_tid,
                }
                axios
                    .get('reviews_date', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('reviews_date err', err.stack)
                        reject(err)
                    })
            })
        },
        couriers_salary({ commit }, data) {
            return new Promise((resolve, reject) => {
                const params = {
                    operator_tid: this.state.operator_tid,
                    date1: data.date1,
                    date2: data.date2,
                    deliveryTime: true,
                }
                axios
                    .get('couriers_salary', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('couriers_salary err', err.stack)
                        reject(err)
                    })
            })
        },
        sms_send({ commit }, data) {
            return new Promise((resolve, reject) => {
                const params = {
                    point_id: data.point_id,
                    phone: data.phone,
                    text: data.text,
                }
                axios
                    .get('sms_send', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('sms_send err', err.stack)
                        reject(err)
                    })
            })
        },
        sms_status({ commit }, data) {
            return new Promise((resolve, reject) => {
                const params = {
                    point_id: data.point_id,
                    message_id: data.message_id,
                }
                axios
                    .get('sms_status', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('sms_status err', err.stack)
                        reject(err)
                    })
            })
        },
        sms_balance({ commit }, data) {
            return new Promise((resolve, reject) => {
                const params = {
                    point_id: data.point_id,
                }
                axios
                    .get('sms_balance', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('sms_balance err', err.stack)
                        reject(err)
                    })
            })
        },
        sms_limit({ commit }, data) {
            return new Promise((resolve, reject) => {
                const params = {
                    point_id: data.point_id,
                }
                axios
                    .get('sms_limit', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('sms_limit err', err.stack)
                        reject(err)
                    })
            })
        },
        sms_check({ commit }, data) {
            return new Promise((resolve, reject) => {
                const params = {
                    point_id: data.point_id,
                    api_id: data.api_id,
                }
                axios
                    .get('sms_check', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('sms_check err', err.stack)
                        reject(err)
                    })
            })
        },
        orders_fee_calculate({ commit }, data) {
            return new Promise((resolve, reject) => {
                data.operator_tid = this.state.operator_tid
                data.city = this.state.settings.city
                axios
                    .post('orders_fee_calculate', data)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('orders_fee_calculate err', err.stack)
                        reject(err)
                    })
            })
        },
        orders_deleted({ commit }, data) {
            console.log('orders_deleted start store')
            return new Promise((resolve, reject) => {
                if (!data.courier) data.courier = 0
                if (!data.guest_id) data.guest_id = 0
                if (!data.point_id) data.point_id = 0
                if (!data.point_ids) data.point_ids = 0
                if (!data.point_tid) data.point_tid = 0
                if (!data.mode) data.mode = 0
                if (!data.deliveryTime) data.deliveryTime = 0
                const params = {
                    operator_tid: this.state.operator_tid,
                    date1: data.date1,
                    date2: data.date2,
                    guest_id: data.guest_id,
                    point_id: data.point_id,
                    point_ids: data.point_ids,
                    point_tid: data.point_tid,
                    courier: data.courier,
                    mode: data.mode,
                    deliveryTime: data.deliveryTime,
                }
                axios
                    .get('orders_deleted', { params })
                    .then(resp => {
                        console.log('test response store deleted', resp)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('orders_deleted err', err.stack)
                        reject(err)
                    })
            })
        },
        orders_date({ commit }, data) {
            return new Promise((resolve, reject) => {
                if (!data.courier) data.courier = 0
                if (!data.guest_id) data.guest_id = 0
                if (!data.point_id) data.point_id = 0
                if (!data.point_ids) data.point_ids = 0
                if (!data.point_tid) data.point_tid = 0
                if (!data.mode) data.mode = 0
                if (!data.deliveryTime) data.deliveryTime = 0
                const params = {
                    operator_tid: this.state.operator_tid,
                    date1: data.date1,
                    date2: data.date2,
                    guest_id: data.guest_id,
                    point_id: data.point_id,
                    point_ids: data.point_ids,
                    point_tid: data.point_tid,
                    courier: data.courier,
                    mode: data.mode,
                    deliveryTime: data.deliveryTime,
                }
                axios
                    .get('orders_date', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('err orders_date', err.stack)
                        reject(err)
                    })
            })
        },
        objects({ commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .get('objects')
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('objects err', err.stack)
                        reject(err)
                    })
            })
        },
        point_users({ commit }, data) {
            return new Promise((resolve, reject) => {
                let params
                if (data) {
                    params = {
                        point_name: data.point_name,
                    }
                } else {
                    params = {
                        operator_tid: this.state.operator_tid,
                    }
                }
                axios
                    .get('point_users', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('point_users err', err.stack)
                        reject(err)
                    })
            })
        },
        point_get({ commit }, point_id) {
            return new Promise((resolve, reject) => {
                const params = {
                    id: point_id,
                }
                axios
                    .get('point', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('point_get err', err.stack)
                        reject(err)
                    })
            })
        },
        point_update({ commit }, point) {
            return new Promise((resolve, reject) => {
                const params = {
                    point,
                }
                axios
                    .post('point', point)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('point_update err', err.stack)
                        reject(err)
                    })
            })
        },
        points_get({ commit }) {
            return new Promise((resolve, reject) => {
                const params = {
                    operator_tid: this.state.operator_tid,
                }
                axios
                    .get('points', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('points_get err', err.stack)
                        reject(err)
                    })
            })
        },
        guests_get({ commit }, data) {
            return new Promise((resolve, reject) => {
                if (!data.point_id) data.point_id = 0
                if (!data.dateCreate) data.dateCreate = 0
                if (!data.dateVisit) data.dateVisit = 0
                const params = {
                    operator_tid: this.state.operator_tid,
                    date1: data.date1,
                    date2: data.date2,
                    point_id: data.point_id,
                    dateCreate: data.dateCreate,
                    dateVisit: data.dateVisit,
                }
                axios
                    .get('guests', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('guests_get err', err.stack)
                        reject(err)
                    })
            })
        },
        points_set({ commit }, points) {
            return new Promise((resolve, reject) => {
                axios
                    .post('points', points)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('points_set err', err.stack)
                        reject(err)
                    })
            })
        },
        points_update_coordinates({ commit }) {
            return new Promise((resolve, reject) => {
                const params = {
                    city: this.state.settings.city,
                    operator_tid: this.state.operator_tid,
                }
                axios
                    .post('points_update_coordinates', params)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('points_update_coordinates err', err.stack)
                        reject(err)
                    })
            })
        },
        point_update_coordinates({ commit }, point_id) {
            return new Promise((resolve, reject) => {
                const params = {
                    city: this.state.settings.city,
                    point_id,
                }
                axios
                    .post('point_update_coordinates', params)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('point_update_coordinates err', err.stack)
                        reject(err)
                    })
            })
        },
        point_delete({ commit }, id) {
            console.log('point_delete id', id)
            return new Promise((resolve, reject) => {
                axios
                    .delete('point', { data: { id } })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('point_delete err', err.stack)
                        reject(err)
                    })
            })
        },
        point_address({ commit }, data) {
            return new Promise((resolve, reject) => {
                const params = {
                    city: this.state.settings.city,
                    point_name: data.point_name,
                    point_id: data.point_id,
                    address: data.address,
                }
                axios
                    .get('point_address', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('point_address err', err.stack)
                        reject(err)
                    })
            })
        },
        address_to_geofence({ commit }, address) {
            return new Promise((resolve, reject) => {
                const params = {
                    city: this.state.settings.city,
                    operator_tid: this.state.operator_tid,
                    address,
                }
                axios
                    .get('address_to_geofence', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('address_to_geofence err', err.stack)
                        reject(err)
                    })
            })
        },
        couriers_get({ commit }) {
            return new Promise((resolve, reject) => {
                const params = {
                    operator_tid: this.state.operator_tid,
                }
                axios
                    .get('couriers', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('couriers_get err', err.stack)
                        reject(err)
                    })
            })
        },
        couriers_orders_today_get({ commit }, point_id) {
            return new Promise((resolve, reject) => {
                const params = {
                    operator_tid: this.state.operator_tid,
                    orders_today: 1,
                    point_id,
                }
                axios
                    .get('couriers', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('couriers_orders_today_get err', err.stack)
                        reject(err)
                    })
            })
        },
        auth({ commit }, user) {
            return new Promise((resolve, reject) => {
                commit('auth_request')
                commit('secu_init')

                console.log('user', user)

                axios
                    .post('login', {
                        name: user.name,
                        pass: user.password,
                    })
                    .then(resp => {
                        if (resp.data.success) {
                            axios.defaults.headers.common['Authorization'] = 'Bearer ' + resp.data.token
                            commit('auth_success', resp.data)
                        }
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('auth err', err.stack)
                        reject(err)
                    })
            })
        },
        logout({ commit }) {
            return new Promise((resolve, reject) => {
                commit('logout')
                this.state.geofences = {}
                delete axios.defaults.headers.common['Authorization']
                resolve()
            })
        },
        quick_answers_get({ commit }, id) {
            return new Promise((resolve, reject) => {
                const params = {
                    point_id: id,
                }
                axios
                    .get('/quick_answers', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('quick_answers_get err', err.stack)
                        reject(err)
                    })
            })
        },
        quick_answer_create({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/quick_answer', {
                        name: data.name,
                        point_id: data.point_id,
                        food: data.food,
                        delivery: data.delivery,
                        isShowed: data.isShowed,
                    })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('quick_answer_create err', err.stack)
                        reject(err)
                    })
            })
        },
        quick_answers_update({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .put('/quick_answers', {
                        id: data.id,
                        name: data.name,
                        point_id: data.point_id,
                        food: data.food,
                        delivery: data.delivery,
                        isShowed: data.isShowed,
                    })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('quick_answers_update err', err.stack)
                        reject(err)
                    })
            })
        },
        quick_answers_delete({ commit }, id_answer) {
            return new Promise((resolve, reject) => {
                const params = {
                    id: id_answer,
                }
                axios
                    .delete('/quick_answers', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('quick_answers_delete err', err.stack)
                        reject(err)
                    })
            })
        },
        point_fieldOfActivity_update({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/points_field_of_activity', payload)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('point_fieldOfActivity_update err', err.stack)
                        reject(err)
                    })
            })
        },
        point_average_check({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/point_average_check', payload)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('point_average_check err', err.stack)
                        reject(err)
                    })
            })
        },
        get_media_review({ commit }, id) {
            return new Promise((resolve, reject) => {
                const params = {
                    review_id: id,
                }
                axios
                    .get('/review/media', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('get_media_review err', err.stack)
                        reject(err)
                    })
            })
        },
        get_media_file({ commit }, path_file) {
            return new Promise((resolve, reject) => {
                const params = {
                    path: path_file,
                }
                axios
                    .get('/media_file', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('get_media_file err', err.stack)
                        reject(err)
                    })
            })
        },
        get_proceeds_analytic({ commit }, params) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/get_proceeds_analytic', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        get_average_price_delivery({ commit }, params) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/get_average_price_delivery', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
    },
    
    getters: {
        isLoggedIn: state => {
            return state.token && state.token.length && !!state.secu6
        },
        //     authStatus: state => state.status,
        //     authUser: state => state.user,
        //     settings: state => state.settings,
        //     token: state => state.token,
    },
})
