<template>
    <v-app id="app">
        <v-main>
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
    export default {
        name: 'App',
        computed: {
        },
        methods: {
            logout: function() {
                console.log('logout')
                this.$store.dispatch('logout').then(() => {
                    this.$router.push({ name: 'login' })
                })
            },
        },
        created: function() {
            console.log('App created')
            this.$http.interceptors.response.use(undefined, function(err) {
                return new Promise(function(resolve, reject) {
                    console.log('check err.status every query', err.stack)
                    if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
                        this.$store.dispatch('logout')
                    }
                    throw err
                })
            })
        },
    }
</script>

<style lang="stylus">
    html
        overflow-y auto !important

    #app
        width 100%
        height 100%
        z-index 1
        display block
        position absolute

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button, input::-webkit-inner-spin-button
        -webkit-appearance none
        margin 0

    /* Firefox */
    input[type=number]
        -moz-appearance textfield

</style>
