<template>
    <div style="height: 100%">
        <v-navigation-drawer app v-model="drawer" :width="210" :fixed="true" :color="'#031E4D'" dark>
            <!-- <v-navigation-drawer app v-model="drawer" :width="240" :fixed="true" :color="'#031E4D'" :expand-on-hover="false" :mini-variant="false" :right="false" :permanent="true" dark> -->
            <v-list dense nav class="py-0">
                <v-list-item two-line :class="miniVariant && 'px-0'">
                    <!-- <v-list-item-avatar>
                        <img src="../assets/images/darall.jpg" />
                    </v-list-item-avatar>

                    <v-tooltip bottom> -->
                        <!-- <template v-slot:activator="{ on, attrs }">
                            <td class="tooltip-text" v-bind="attrs" v-on="on">
                                <img class="shapka" src="../assets/images/shapka.png" />
                            </td>
                        </template>
                        <span>C Новым 2022 годом!</span>
                        <span>C Наступающим 2022 годом!</span>
                    </v-tooltip>
                    <v-list-item-content class="ml-2"> -->
                    
                    <!-- </v-tooltip>
                    <v-list-item-content>

                        Darall Pro
                        <v-list-item-subtitle>DELIVERY CONSOLE</v-list-item-subtitle>
                    </v-list-item-content> -->

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <td class="tooltip-text" v-bind="attrs" v-on="on">
                                <img src="../assets/images/DarallPro_logo_invert_screen_transparent.svg" class="mx-0 mt-4" width=150 />
                            </td>
                        </template>
                        <span>darall.pro - delivery console</span>
                    </v-tooltip>

                </v-list-item>

                <v-divider></v-divider>

                <v-list-item v-for="(item, index) in menuItems" :key="index" link :to="{ name: item.name }" @change="menuItemChange(index)">
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        {{ $t('menu.' + item.name) }}
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        

        <v-app-bar class="main-bar" :elevation="1" height="64" app>
            <!-- <template slot="progress"> -->
            <!-- </template> -->

            <v-app-bar-nav-icon class="ml-0" @click="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title class="pl-2">{{ toolbarTitle }}</v-toolbar-title>

            <manage-settings v-if="this.$store.state.isSettings && !this.$store.state.settingsLock"></manage-settings>

            <v-btn v-if="this.$store.state.isLogistic" @click="windowOpenClose" x-small color="deep-purple accent-4" dark class="ml-5 mr-5" >Управление заказами</v-btn>

            <v-spacer></v-spacer>

            {{ accountName() }}

            <div class="text-center ml-2">
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon large>mdi-account</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-for="(item, index) in profileItems" :key="index" @click="item.func">
                            <v-list-item-icon>
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>

                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </v-app-bar>
        
        <v-main class="main-content" app>
            <v-progress-linear class="main-loading" v-if="$store.state.loading" indeterminate absolute bottom color="blue"></v-progress-linear>
            <router-view />
        </v-main>
    </div>
</template>

<script>
    import config from '../config'
    // import ManageLogistic from './manage/ManageLogistic.vue'
    import ManageSettings from './settings/ManageSettings.vue'

    export default {
        data() {
            return {
                drawer: null,
                menuItems: [],
                color: 'primary',
                colors: ['primary', 'blue', 'success', 'red', 'teal'],
                right: false,
                isOperator: false,
                // dark: true,
                permanent: true,
                miniVariant: false,
                expandOnHover: false,
                background: false,
                profileItems: [
                    // { title: 'Профиль', func: this.onProfile, icon: 'mdi-cog-outline' },
                    { title: 'Выход', func: this.onExit, icon: 'mdi-exit-to-app' },
                ],
                toolbarTitle: '',
                toolbarContent: '---',
                main_content: 'Hello World!',
            }
        },
        components: {
            // ManageLogistic,
            ManageSettings,
            
        },
        mounted() {
            if (this.$store.state.user.role == config.user.role.operator) {
                console.log('OPERATOR')
                this.menuItems.push({ name: 'analitics', icon: 'mdi-chart-donut' })
                this.menuItems.push({ name: 'orders', icon: 'mdi-inbox-multiple' })
                this.menuItems.push({ name: 'points', icon: 'mdi-home-group' })
                this.menuItems.push({ name: 'couriers', icon: 'mdi-car-multiple' })
                this.menuItems.push({ name: 'guests', icon: 'mdi-account-multiple' })
                this.menuItems.push({ name: 'reviews', icon: 'mdi-comment-text-multiple-outline' })
                this.menuItems.push({ name: 'logistic', icon: 'mdi-map-marker-radius-outline' })
                this.menuItems.push({ name: 'geofence', icon: 'mdi-vector-rectangle' })
                this.menuItems.push({ name: 'subscribe', icon: 'mdi-account-cash' })
                this.menuItems.push({ name: 'aboutPoint', icon: 'mdi-information' })
                this.menuItems.push({ name: 'settings', icon: 'mdi-cog' })
                this.isOperator = true
            } else {
                this.menuItems.push({ name: 'analitics', icon: 'mdi-chart-donut' })
                this.menuItems.push({ name: 'orders', icon: 'mdi-inbox-multiple' })
                this.menuItems.push({ name: 'guests', icon: 'mdi-account-multiple' })
                this.menuItems.push({ name: 'reviews', icon: 'mdi-comment-text-multiple-outline' })
                this.menuItems.push({ name: 'logistic', icon: 'mdi-map-marker-radius-outline' })
                this.menuItems.push({ name: 'geofence', icon: 'mdi-vector-rectangle' })
                this.menuItems.push({ name: 'subscribe', icon: 'mdi-account-cash' })
                // this.menuItems.push({ name: 'aboutPoint', icon: 'mdi-information' })
                // this.menuItems.push({ name: 'statistics', icon: 'mdi-order-numeric-ascending' })
                if (this.$store.state.user.role == config.user.role.point) {
                    // this.menuItems.push({ name: 'aboutPoint', icon: 'mdi-information' })
                    // this.menuItems.push({ name: 'settings', icon: 'mdi-cog' })
                }
            }

            console.log('this.$router.currentRoute', this.$router.currentRoute)
            const index = this.menuItems.findIndex(item => item.name == this.$router.currentRoute.name)
            this.menuItemChange(index)

            this.$store.state.loading = false
        },
        computed: {},
        methods: {
            accountName() {
                let account_name = ''
                if (this.$store.state.user.role == config.user.role.point) {
                    if (this.$store.state.user.point) {
                        account_name += this.$store.state.user.point.name + ' - '
                    } else {
                        account_name += 'Unknown - '
                    }
                }
                account_name += this.$store.state.user.name
                return account_name
            },
            onProfile() {},
            onExit() {
                this.$store.dispatch('logout').then(() => this.$router.push({ name: 'login' }))
            },
            menuItemChange(index) {
                this.toolbarTitle = this.$t(this.menuItems[index].name + '.title')
                this.$store.state.isLogistic = false
                this.$store.state.isSettings = false
                if (this.toolbarTitle == 'Логистика' && !this.$store.state.userIsPoint) {
                    this.$store.state.isLogistic = true
                } else if (this.toolbarTitle == 'Настройки') {
                    this.$store.state.isSettings = true
                }
            },
            windowOpenClose() {
                this.$store.state.isOrderWindowClose = !this.$store.state.isOrderWindowClose
            },
        },
    }
</script>

<style lang="stylus" scoped>
    .v-sheet.v-card
      border-radius 0
    //   border-top-right-radius 0
    //   border-bottom-right-radius 0
    .main
        width 100%
        height 100%
    .v-card__text
        padding 0
    .main-bar
        // left 256px
    .main-content
        padding 0 !important
        height 100%
    .v-divider
        padding-bottom 8px
    .v-application
        height 100% !important
    .v-main
        height 100% !important
    .container
        height 100%

    .v-list-item--two-line .v-list-item__avatar:not(.v-list-item__avatar--horizontal)
        margin-top 0
        margin-bottom 0

    .v-application--is-ltr .v-list-item__icon:first-child
        margin-right 14px
        margin-left 6px

    .shapka
        width 75px
        position absolute
        left 8px
        top -7px
</style>

<style lang="stylus">
    .order-card
        cursor default
        margin 10px 15px
        font-size 13px

    .order-id
        cursor default
        margin 0 15px
        color #bbb

    .item-order
        background-color grey
        color white
        cursor default
        margin 1px
        padding 0 4px
        border-radius 2px
        font-size 12px
        font-weight 600
        box-shadow 1px 1px 1px grey
        height 18px
        line-height 18px

    // .v-text-field
    //     margin-top 0
    //     padding-top 0

    .main-loading
        z-index 10000
        top 0px

    // notify
    .v-snack__content
        display flex
        align-items center
        justify-content space-between
        font-size 16px
        font-weight 400
        color white
</style>
