let config = require('./config.global')

config.env = 'development'
config.hostname = 'localhost'
config.mongo.db = 'local_db'

config.api.delivery = 'http://localhost:5500/'
// config.api.delivery = 'https://api.darall.pro/v1/'

config.geofence.savetime = 30 // 3 sec

module.exports = config;
